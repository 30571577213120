import React from "react"
import { Container, Row, Carousel } from "react-bootstrap"
import FeedbackStar from "./../../assets/feedback-star.svg"
import LogoLeft from "../../assets/logo-testimonial-left.svg"
import LogoRight from "../../assets/logo-testimonial-right.svg"
import ArrowRight from "../../assets/arrow-right.svg"
import ArrowLeft from "../../assets/arrow-left.svg"

import "./testimonialSection.css"

const TestimonialSection = () => {
  const quotes = [
    {
      quote: `Big thanks to the team. On a self-inflicted pretty tight turnaround on a commercial property purchase, they were able to deliver a simply outstanding financing outcome with a minimum of fuss and a maximum of professionalism. Highly recommended!`,
      date: "July 2024",
      author: "Richard M | Collingwood | VIC",
    },
    {
      quote: `A big thanks to the team for assisting us to secure finance for our first home.  Due to our unique circumstances, we required specialised assistance to maximise our borrowing capacity. They were highly professional, a pleasure to work with and they got the job done!  We look forward to engaging their services again in the future and will definitely tell our friends…..already have :)`,
      date: "March 2024",
      author: "Georgia and Mete | Coburg | VIC",
    },
    {
      quote: `A quick shout out to the team for a great service. I manage my own Business Advisory and Accounting practice 
      and I am impressed with the Compare n Save platform. I compared my own Westpac SMSF loan and was presented with many options to save. 
      I then reached out directly to Compare n Save and they negotiated a rate lower than advertised, saving me close to 3% p.a. 
      The team also managed the refinance process, and I am now using Compare n Save to help our clients.`,
      date: "February 2024",
      author: "Dean | CPA | DNAdvisory | Qld",
    },
    {
      quote: `The team effectively negotiated with a range of lenders on our behalf and were able to secure finance at a very competitive 
      rate based on our personal circumstances. They were professional, attentive, and took the hassle out of the process, meaning we 
      could give our full attention to finding the right home.`,
      date: "January 2024",
      author: "Lynden and Nina | Coburg, VIC",
    },
    {
      quote: `Thanks to the team at Compare n Save – A great outcome only 2 days after applying. 
      Thanks for your help, made that a lot easier than it otherwise would’ve been.`,
      date: "September 2023",
      author: "Morgan B | Toorak, VIC",
    },
    {
      quote: `We would definitely recommend Comparensave if you are needing to refinance your mortgage or for any other mortgage needs. 
      Sal provided us with mortgage options that benefited both our financial needs and goals. He delivered this service in a timely and 
      efficient manner and his warmth and professional demeanour made for a great experience all round.`,
      date: "September 2023",
      author: "David LS and Belinda W | Kingsville, VIC",
    },
    {
      quote: `Absolutely top rate, premium service. Sal knows exactly what he is doing, 
      has access to a fantastic panel of lenders, works extremely efficiently and makes 
      the whole process very smooth. He obtained excellent interest rates for me. I have 
      recommended family and friends to him and am grateful I have him on my side for finance needs.`,
      date: "August 2023",
      author: "Milli P | Brighton, VIC",
    },
    {
      quote: `If you are looking for a great finance broker, reach out to Compare Save. 
      They saved me 1% on our large home loan in less than an hour (even though I have been with my bank for 30 years).
      Whether it is refinancing, a new home loan or a SMSF loan, they will show you ways to save money.`,
      date: "August 2023",
      author: "Rob D | Camberwell, VIC",
    },
    {
      quote: `Thanks to Sal and the team for the fast work and loan negotiations - smooth and easy process!`,
      date: "August 2023",
      author: "Jason R | Hampton, VIC",
    },
    {
      quote: `The team at Compare n Save were great in helping me arrange a loan for a new house 
      I recently purchased - the whole process was quick and seamless. They are very professional 
      and efficient. I highly recommend Compare n Save.`,
      date: "July 2023",
      author: "Rosemarie A | Sandringham, VIC",
    },
    {
      quote: `Thankyou to you and your team for organising finance for our recently purchased 
      property so quickly! Thankyou also for negotiating a better rate for us too – this will 
      come in very handy. Lucy and I are both looking forward to working with you again in the future.`,
      date: "July 2023",
      author: "Hamish and Lucy W | Warrandyte, VIC",
    },
    {
      quote: `Compare n Save's expertise and knowledge made the experience of 
      purchasing my first property and investment property easy and seamless. Their 
      ongoing communication and ability to be one step ahead made the whole process stress free.`,
      date: "June 2023",
      author: "Stefan v H | Mulgrave, VIC",
    },
    {
      quote: `Compare n Save was 
      recommended by our accountant - absolutely fantastic and I strongly 
      recommend them. They listened and actioned every request and are 
      always responsive.....they get things done.`,
      date: "May 2023",
      author: "John T | Northbridge, NSW",
    },
    {
      quote: `The team at Compare n Save have been helping us with for many years, 
      starting with a simple refinance on our family home. Over the years, we 
      settled a number of more complex deals including investment properties 
      and Self-Managed-Super-Fund loans and none of this would have been 
      possible without the team's expertise and vast network of contacts. 
      As a self-employed Director of a medium sized business, securing finance 
      can be more difficult than it is for an emplopyee - the team not only took 
      care of all the hard work for us but they also achieved an outcome that we 
      would have struggled to get on our own. Always prompt and a pleasure to 
      deal with, I couldn’t recommend Compare n Save more highly.`,
      date: "May 2023",
      author: "Shannon O | Mt Eliza, VIC",
    },
    {
      quote: `I highly recommend Compare n Save for the clear and genuine service provided to me 
      over the past few years. They first assisted me as a 23 year old first home 
      buyer. I couldn’t have asked for a better team to help me with what initially 
      felt like a daunting and significant event. They certainly ensured everything 
      on the finance side of things was stress free. Since then, they have helped 
      with another purchase, and hopefully many more to come. Compare n Save 
      also assisted my friends with their first home purchasing, they received the 
      same outstanding service. Thanks for your genuine interest and support.`,
      date: "May 2024",
      author: "Angus F | Hawthorn, VIC",
    },
    {
      quote: `Compare n Save have been outstanding! They are always available for 
      their clients and have been incredibly helpful with navigating a path for a 
      first time mortgage holder.`,
      date: "April 2023",
      author: "Lachlan G | South Yarra, VIC",
    },
    {
      quote: `I’m so grateful for the patience and transparency of the Compare n Save 
      team. They presented all the options, provided the relevant information 
      and gave me the breathing space to make a decision that suited my needs. 
      Whether it’s your first home or an investment property, I'd recommend 
      Compare n Save to anyone.`,
      date: "April 2023",
      author: "Niamh S | Coolangatta, QLD",
    },
    {
      quote: `I really appreciated the no-nonsense approach Compare n Save showed 
      from the very beginning. Everything was clear and simple, with no jargon 
      to confuse the process. It gave me the confidence to make a decision that 
      felt right for me.`,
      date: "April 2023",
      author: "Lachlan H | Coolangatta, QLD",
    },
    {
      quote: `The Compare n Save team navigated the borrowing process for us from 
      start to end, ensuring we found a solution that maximised our opportunities 
      - today and into the future. It saved us so much time by not having to shop 
      around ourselves, while still allowing us to find a competitive solution.`,
      date: "April 2023",
      author: "Maria S | Kingscliff, NSW",
    },
  ]

  return (
    <div className="testimonial-wrapper">
      <div className="testimonial-logo-container testimonial-logo-left-container">
        <LogoLeft className="testimonial-logo testimonial-logo-left" />
      </div>
      <div className="testimonial-logo-container testimonial-logo-right-container">
        <LogoRight className="testimonial-logo testimonial-logo-right" />
      </div>

      <Container className="testimonial-container">
        <Row className="testimonial-row">
          <h2 className="testimonial-tagline">Compared. Saved.</h2>
        </Row>
        <Row>
          <TestimonialSlide quotes={quotes} />
        </Row>
      </Container>
    </div>
  )
}

const TestimonialSlide = ({ quotes }) => {
  return (
    <Carousel
      interval={null}
      prevLabel=""
      nextLabel=""
      prevIcon={<ArrowLeft className="testimonial-control-icon" />}
      nextIcon={<ArrowRight className="testimonial-control-icon" />}
      indicators={false}
    >
      {quotes.map(({ quote, author, date }) => {
        return (
          <Carousel.Item>
            <div className="testimonial-feedback-star">
              <FeedbackStar />
            </div>
            <div className="testimonial-feedback">
              <p className="testimonial-feedback-quotation-up">“</p>
              <p>{quote}</p>
              <p className="testimonial-feedback-quotation-down">“</p>
              <div className="testimonial-feedback-author">
                {" "}
                {author} - {date}
              </div>
            </div>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}

export default TestimonialSection
