import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import CustomButton from "../button"
import { navigate } from "gatsby"

import "./loanExplainSection.css"

const LoanExplainSection = () => {
  return (
    <div className="loan-wrapper">
      <Container className="loan-container">
        <Row className="loan-row">
          <Col className="loan-tagline">
            <h2>Home loans explained</h2>
          </Col>
        </Row>
        <Row className="loan-row">
          <Col className="loan-slogan">
            <p>
              Whether you’re buying a property or looking to refinance an
              existing mortgage, understanding industry jargon is key to finding
              your home loan match.
            </p>
          </Col>
        </Row>
        <Row className="loan-row">
          <Col className="loan-button-row">
            <CustomButton
              variant="secondary"
              className="loan-button"
              onClick={() => {
                navigate("/glossary")
              }}
            >
              Explore More
            </CustomButton>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LoanExplainSection
