import React, { useContext } from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import CustomButton from "../button"
import { GlobalContext } from "../../context/provider.js"
import EnterIcon from "../../assets/features-enter.svg"
import CompareIcon from "../../assets/features-compare.svg"
import SelectIcon from "../../assets/features-select.svg"
import ConnectIcon from "../../assets/connect-icon.svg"
import { navigate } from "gatsby"

import "./featuresSection.css"

const FeaturesSection = () => {
  const { dispatch } = useContext(GlobalContext)

  return (
    <Container className="features-container">
      <Row className="features-row">
        <h2 className="features-tagline">Hassle-free savings</h2>
      </Row>
      <Row className="features-row">
        <Col className="features-slogan">
          <p>
            Search, review and compare 100s of home loans from leading Australian
            lenders – all in just a few clicks and we won’t call, email or text
            unless you ask.
          </p>
        </Col>
      </Row>
      <Row xs={1} md={1} lg={2} xl={4} className="g-4">
        <Col>
          <Card className="features-card-odd">
            <div className="features-card-img">
              <EnterIcon />
            </div>
            <Card.Body>
              <Card.Title className="features-card-title">Enter</Card.Title>
              <Card.Text className="features-card-detail">
                Just enter a few simple details to tell us about the loan you
                want.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="features-card-even">
            <div className="features-card-img">
              <CompareIcon />
            </div>
            <Card.Body>
              <Card.Title className="features-card-title">Compare</Card.Title>
              <Card.Text className="features-card-detail">
                Compare 100s of home loans and see how much you can save.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="features-card-odd">
            <div className="features-card-img">
              <SelectIcon />
            </div>
            <Card.Body>
              <Card.Title className="features-card-title">Select</Card.Title>
              <Card.Text className="features-card-detail">
                Select the home loan that interests you the most.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="features-card-even">
            <div className="features-card-img">
              <ConnectIcon width="120.52" height="120.52" />
            </div>
            <Card.Body>
              <Card.Title className="features-card-title">Connect</Card.Title>
              <Card.Text className="features-card-detail">
                Request a callback to connect with our team who will guide you
                through the home loan journey.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="features-row">
        <Col>
          <CustomButton
            className="features-button"
            onClick={() => {
              dispatch({ type: "SET_REFINANCE", refinance: false })
              navigate("/product")
            }}
          >
            Get started
          </CustomButton>
        </Col>
      </Row>
    </Container>
  )
}

export default FeaturesSection
