import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import AdelaideLogo from "./../../images/logo-transparency/Adelaide_Bank.png"
import Macquarie from "./../../images/logo-transparency/Macquarie.png"
import Amp from "./../../images/logo-transparency/amp.png"
import Anz from "./../../images/logo-transparency/anz.png"
import BankOfMelbourne from "./../../images/logo-transparency/Bank_of_Melbourne.png"
import BankWest from "./../../images/logo-transparency/Bankwest.png"
import Cba from "./../../images/logo-transparency/CBA.png"
import Citibank from "./../../images/logo-transparency/Citibank.png"
import Heritage from "./../../images/logo-transparency/Heritage.png"
import Ing from "./../../images/logo-transparency/ING.png"
import Libery from "./../../images/logo-transparency/Libery.png"
import Me from "./../../images/logo-transparency/me.png"
import Nab from "./../../images/logo-transparency/nab.png"
import Suncorp from "./../../images/logo-transparency/suncorp.png"
import VirginMoney from "./../../images/logo-transparency/Virgin_Money.png"
import Westpac from "./../../images/logo-transparency/westpac.png"

import "./companySection.css"

const CompanySection = () => {
  const allCompany = [
    Macquarie,
    Me,
    Anz,
    AdelaideLogo,
    BankOfMelbourne,
    Amp,
    Nab,
    Suncorp,
    VirginMoney,
    Cba,
    Citibank,
    BankWest,
    Heritage,
    Ing,
    Westpac,
    Libery,
  ]
  return (
    <div className="company-wrapper">
      <Container className="company-container">
        <Row className="company-row">
          <Col className="company-tagline">
            <h2>We’re in good company</h2>
          </Col>
        </Row>
        <Row className="company-row">
          <Col className="company-slogan">
            <p>
              We only compare and arrange home loans with Australia’s leading
              banks and other lenders.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="company-list-container">
        {allCompany.map(e => {
          return (
            <div>
              <img alt="logo" src={e} />
            </div>
          )
        })}
      </Container>
    </div>
  )
}

export default CompanySection
