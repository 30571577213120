import React, { useContext } from "react"
import { GlobalContext } from "../context/provider.js"
import { navigate } from "gatsby"
import CustomButton from "./button.js"
import Banner from "./banner"
import FeaturesSection from "./home/featuresSection"
import ContactSection from "./home/contactSection"
import CompanySection from "./home/companySection"
import OffersSection from "./home/offersSection"
import InsightSection from "./home/insightSection"
import TestimonialSection from "./home/testimonialSection"
import LoanExplainSection from "./home/loanExplainSection"
import FollowSection from "./home/followSection"
import BrowserMessageModal from "../components/browserMessageModal"
import "./home.css"

const Home = () => {
  const { dispatch } = useContext(GlobalContext)

  return (
    <div className="home">
      <Banner />
      <div className="home-body">
        <div className="home-body-header">
          <h1>
            <strong>Compare n Save</strong>
            <br />
            in seconds
          </h1>
          <p>
            Compare 100s of home loans, investment loans and SMSF loans from
            Australia’s most trusted lenders
          </p>
          <div className="button-container">
            <CustomButton
              variant="secondary"
              className="button button-secondary"
              onClick={() => {
                dispatch({ type: "SET_REFINANCE", refinance: true })
                navigate("/product")
              }}
            >
              I want to <strong>refinance</strong>
            </CustomButton>
            <CustomButton
              variant="transparent"
              className="button button-transparent"
              onClick={() => {
                dispatch({ type: "SET_REFINANCE", refinance: false })
                navigate("/product")
              }}
            >
              I need a <strong>new loan</strong>
            </CustomButton>
          </div>
        </div>
        <FeaturesSection />
        <ContactSection />
        <CompanySection />
        <OffersSection />
        <InsightSection />
        <TestimonialSection />
        <LoanExplainSection />
        <FollowSection />
        <BrowserMessageModal />
      </div>
    </div>
  )
}

export default Home
