import React from "react"
import { navigate } from "gatsby"
import { Card } from "react-bootstrap"
import CustomButton from "../button"
import OffersSectionImage from "../../assets/home-offers-section.svg"
import "./offersSection.css"

const OffersSection = () => {
  return (
    <div className="offers-section-wrapper">
      <div className="offers-section-container">
        <div className="offers-section-content">
          <h2 className="text-white">It pays to switch</h2>
          <p className="text-white">
            The home loan market is fiercely competitive and sometimes it pays
            to switch. Explore the latest incentives and cashback offers from
            our lenders.
          </p>
          <CustomButton
            variant="secondary"
            className="offers-section-button"
            onClick={() => {
              navigate("/offers")
            }}
          >
            See our offers
          </CustomButton>
        </div>
        <Card className="offers-section-card">
          <div className="offers-section-card-img">
            <OffersSectionImage width="168" height="168" />
          </div>
          <Card.Body>
            <h2>Our latest special offers</h2>
            <h5>Cashback bonuses</h5>
            <h5>Fee waivers</h5>
            <h5>1st home buyer incentives </h5>
            <CustomButton
              variant="secondary"
              className="offers-section-button"
              onClick={() => {
                navigate("/offers")
              }}
            >
              See more
            </CustomButton>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default OffersSection
