import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { navigate } from "gatsby"
import CustomButton from "../button"

import "./contactSection.css"

const ContactSection = () => {
  return (
    <div className="wrapper">
      <Container className="contact-container">
        <Row className="contact-row">
          <Col className="contact-tagline">
            <h2>Looking for a lower rate than advertised?</h2>
          </Col>
        </Row>
        <Row className="contact-row">
          <Col className="contact-slogan">
            <p>
              Connect with us to see if we can negotiate a lower interest rate
              for you.
            </p>
          </Col>
        </Row>
        <Row className="contact-row">
          <Col>
            <CustomButton
              variant="secondary"
              className="contact-button"
              onClick={() => {
                navigate("/contact")
              }}
            >
              Request a call back
            </CustomButton>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContactSection
