import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import CustomButton from "../button"
import LogoTv from "./../../assets/logo-tv.svg"
import { navigate } from "gatsby"

import "./followSection.css"

const FollowSection = () => {
  return (
    <div className="follow-section-wrapper">
      <Container fluid>
        <Row>
          <Col className="follow-section-col">
            <div className="follow-section-img">
              <LogoTv />
            </div>
          </Col>
          <Col className="follow-section-col">
            <div className="follow-section-content">
              <h2 className="text-white">Stay in the loop</h2>
              <p className="text-white">
                Sign up to our newsletter to get exclusive insights and all the
                latest home loan news.
              </p>
              <CustomButton
                variant="secondary"
                className="follow-section-button"
                onClick={() => {
                  navigate("/contact")
                }}
              >
                Sign up
              </CustomButton>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FollowSection
